<template>
    <div :class="`${usePlusIcon ? 'accordion-plus' : 'accordion'}${isWrapper ? ` ${wrapperClass}` : ''}`">
        <div class="header" :class="{'header--opened': open}" @click="onClick">
            <div v-if="title" class="title">
                {{ title }}
            </div>
            <slot name="headerContent" />
            <div :class="{'icon': true, open: open}">
                <AtomIcon :name="iconName" :bolding="usePlusIcon ? '2' : '1'" :width="usePlusIcon ? '24' : '16'"/>
                <AtomIcon v-show="usePlusIcon && !open" name="minus" bolding="2" class="plus-dash" width="24"/>
            </div>
        </div>
        <div ref="content" :class="{'content': true, 'open': open}">
            <slot name="innerContent" />
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
    title?: string,
    open: boolean,
    usePlusIcon?: boolean,
    isWrapper?: boolean,
    wrapperClass?: string
}>(), {
    title: undefined,
    wrapperClass: undefined,
    usePlusIcon: false,
    isWrapper: false
});

const content = ref<HTMLElement | null>(null);
const emit = defineEmits<{(e?: 'update:open', open?: boolean): void}>();
const innerPadding = 16;

const onClick = () => {
    emit('update:open', !props.open);
};

const iconName = computed(() => (
    props.usePlusIcon ? 'minus' : 'chevron-down'
));

const setMaxHeight = () => {
    if (content.value) {
        content.value.style.height = `${content.value.scrollHeight + innerPadding}px`;
    }
};

watch(() => props.open, (newVal) => {
    if (props.usePlusIcon) {
        return;
    }

    if (newVal) {
        nextTick(() => {
            setMaxHeight();
        });
    } else {
        content.value ? content.value.style.height = '' : null;
    }
});
</script>

<style lang="scss" scoped>
.accordion {
    width: 100%;
    margin-top: sp(s);

    .header {
        cursor: pointer;

        @include helper-color(state-default);
        @include helper-font-weight(medium);
        @include helper-font-size(default);

        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem(64);

        @include helper-border-t();
    }

    .icon {
        width: rem(64);
        height: rem(64);
        transition: transform 0.3s;

        @include helper-flex-centered();
        @include helper-svg-size(24);

        &.open {
            transform: rotate(180deg);
        }
    }

    .content {
        height: 0;
        overflow: hidden;

        @include helper-border-b();

        &.open {
            overflow: visible;
            height: 100%;
            padding-bottom: sp(s);
        }
    }
}

.accordion-plus {
    width: 100%;

    @include helper-color-bg(white);

    .header {
        cursor: pointer;
        display: flex;

        @include helper-font-weight(regular);
        @include helper-font-size(5);
        @include helper-color(text-primary);

        align-items: center;
        justify-content: space-between;
        height: rem(56);
        padding-left: sp(s);

        &--opened {
            @include helper-color(corporate-blue);
            @include helper-font-weight(bold);
        }
    }

    .icon {
        position: relative;
        width: rem(56);
        height: rem(56);

        @include helper-color(corporate-blue);
        @include helper-flex-centered();
        @include helper-svg-size(24);

        .plus-dash {
            position: absolute;
            top: calc(50% - 0.75rem);
            transform: rotate(90deg);
        }
    }

    .content {
        height: 0;
        overflow: hidden;
        padding-left: sp(m);

        @include helper-border-b();

        &.open {
            overflow: visible;
            height: 100%;
            padding-bottom: sp(s);
        }
    }
}
</style>
